import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import API from "api/API";
import { useDevicesStore } from "contexts/stores";
import { DevIsAlive } from "./DeviceCardGrid";
import { enqueueSnackbar } from "notistack";
import FormButtons from "components/slider/FormButtons";
import CreateDeviceTag from "./CreateDevice/CreateDeviceTag";
import CreateDevicePlant from "./CreateDevice/CreateDevicePlant";
import FormInput from "views/operators/components/FormInput";
import { parse } from "date-fns";
import ReactSelect from "react-select";
import SliderPopup from "components/slider/SliderPopup";
import LocationPicker from "./CreateDevice/LocationPicker";

function CreateDevice({ onClose, deviceData }) {
  const { t } = useTranslation();
  const [readOnly, setReadOnly] = useState(deviceData ? true : false);
  const [tags, setTags] = useState([]);
  const locationDisclosure = useDisclosure();

  const handleLocationSelect = (coordinates, location) => {
    console.log("lcoation select");
    setValue("latitude", coordinates[0]);
    setValue("coordinates", coordinates);
    setValue("longitude", coordinates[1]);
    if (location && location !== "") setValue("location", location);
  };

  const selectOptions = [
    { value: "HMR9", label: "HMR9" },
    {
      value: "PowerSupply",
      label: "PowerSupply",
    },
  ];

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      id: deviceData?.id || 0,
      name: deviceData?.name || "",
      type: deviceData?.type || "",
      location: deviceData?.location || "",
      latitude: deviceData?.latitude || "",
      longitude: deviceData?.longitude || "",
      version: deviceData?.version || "",
      registrationTime:
        deviceData?.registrationDate || new Date().toISOString(),
      alive:
        deviceData?.alive || deviceData?.alive === 0
          ? deviceData?.alive
          : DevIsAlive.OK,
      plant: deviceData?.plant || "",
      tags: tags ?? [],
    },
  });

  useEffect(() => {
    if (deviceData?.id !== undefined) {
      API.getAllDeviceTags(deviceData?.id).then((result) => {
        if (result.isSuccess) {
          const loadedTags = result.data.map((tag) => ({
            value: tag.id,
            label: tag.name,
          }));
          setTags(loadedTags);
          reset({ ...deviceData, tags: loadedTags });
        } else {
          console.error("Error while retrieving tags");
        }
      });
    }
  }, [deviceData]);

  const fetchDevices = useDevicesStore((state) => state.fetchDevices);

  const onSubmit = (data) => {
    if (deviceData) {
      const dataFormatted = {
        ...data,
        type: data.type,
        tags: data.tags?.map((formtag) => ({
          id: tags.find((tag) => tag.label === formtag.label)?.value ?? 0,
          name: formtag.label,
          device: data.id,
        })),
        latitude: parseFloat(data.latitude),
        longitude: parseFloat(data.longitude),
        registrationTime: parse(
          data.registrationDate ?? data.registrationTime,
          "dd/MM/yyyy HH:mm",
          new Date()
        ).toISOString(),
        alive: data.alive || DevIsAlive.OK,
      };

      API.editDevice(dataFormatted).then((result) => {
        if (result.isSuccess) {
          enqueueSnackbar(t("create_device_form.edit_success"), {
            variant: "success",
          });
          fetchDevices();
        } else {
          enqueueSnackbar(t("create_device_form.edit_error"), {
            variant: "error",
          });
        }
      });
    } else {
      const dataFormatted = {
        ...data,
        type: data.type,
        tags: data.tags?.map((tag) => ({ id: 0, name: tag.label })),
        latitude: parseFloat(data.latitude),
        longitude: parseFloat(data.longitude),
        registrationTime: new Date().toISOString(),
        alive: DevIsAlive.OK, //value expected ?
      };

      API.createDevice(dataFormatted).then((result) => {
        if (result.isSuccess) {
          enqueueSnackbar(t("create_device_form.create_success"), {
            variant: "success",
          });
          fetchDevices();
        } else {
          enqueueSnackbar(t("create_device_form.create_error"), {
            variant: "error",
          });
        }
      });
    }
    onClose();
  };

  const checkDeviceNameExists = (name, plantId, excludeId = null) => {
    try {
      const { devices } = useDevicesStore.getState();
      const nameExists = devices.some(
        (device) =>
          device.name.toLowerCase() === name.toLowerCase() &&
          device.id !== excludeId &&
          device.plant === plantId
      );
      return nameExists;
    } catch (error) {
      console.error("Error checking device name:", error);
      return false;
    }
  };

  return (
    <Box p={4}>
      <Box bg="white" p={0}>
        <Heading size="md" pb={"20px"} textAlign={"center"}>
          {deviceData
            ? t("create_device_form.edit_device")
            : t("create_device_form.add_device")}
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CreateDevicePlant
            control={control}
            errors={errors}
            isReadOnly={readOnly}
          />
          <FormInput
            name="name"
            placeholder={t("create_device_form.name")}
            control={control}
            register={register}
            errors={errors}
            validationRules={{
              required: t("create_device_form.id_required"),
              validate: {
                nameIsUnique(value) {
                  const selectedPlant = getValues("plant");
                  const nameExists = checkDeviceNameExists(
                    value,
                    selectedPlant,
                    deviceData?.id
                  );
                  return !nameExists || t("create_device_form.name_exists");
                },
              },
            }}
            isReadOnly={readOnly}
          />

          <FormControl p="0px" isInvalid={errors.type}>
            <Grid
              templateColumns={{ base: "1fr", md: "150px 1fr" }}
              gap={1}
              mb={4}
            >
              <Flex alignItems="center">
                <FormLabel>{t("create_device_form.type")}</FormLabel>
              </Flex>

              <Controller
                name="type"
                control={control}
                rules={{ required: t("create_device_form.type_required") }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <ReactSelect
                    isDisabled={readOnly}
                    onChange={(selectedOption) =>
                      onChange(selectedOption?.value)
                    }
                    onBlur={onBlur}
                    value={
                      selectOptions.find((option) => option.value === value) ||
                      null
                    }
                    ref={ref}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        marginLeft: 8,
                        backgroundColor: "#EEEEEE",
                      }),
                    }}
                    options={selectOptions}
                    menuPlacement="bottom"
                    placeholder={t("create_device_form.type")}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.type && errors.type.message}
              </FormErrorMessage>
            </Grid>
          </FormControl>

          <Grid
            templateColumns={{
              base: "1fr",
              md: "0.5fr 0.5fr",
            }}
            gap={1}
            mb={0}
          >
            <FormInput
              name="location"
              placeholder={t("create_device_form.location")}
              control={control}
              register={register}
              errors={errors}
              validationRules={{
                required: t("create_device_form.location_required"),
              }}
              isReadOnly={true}
            />
            <Flex justifyContent="center">
              <Button
                alignSelf="center"
                onClick={() => {
                  locationDisclosure.onOpen();
                }}
                disabled={readOnly}
                bgColor="pcr.200"
                _hover={{ bgColor: "pcr.100" }}
                maxW={{ base: 230, md: 300 }}
                mb={5}
              >
                {t("create_device_form.select_location")}
              </Button>
            </Flex>
          </Grid>
          <SliderPopup
            isOpen={locationDisclosure.isOpen}
            onClose={locationDisclosure.onClose}
          >
            <LocationPicker
              selectedCoordinates={[
                getValues("latitude"),
                getValues("longitude"),
              ]}
              onLocationSelect={handleLocationSelect}
              onClose={locationDisclosure.onClose}
            />
          </SliderPopup>

          <FormInput
            name="coordinates"
            placeholder={t("create_device_form.coordinates")}
            control={control}
            register={register}
            errors={errors}
            validationRules={{
              required: t("create_device_form.coordinates_required"),
            }}
            isReadOnly={true}
          />

          <FormInput
            name="version"
            placeholder={t("create_device_form.version")}
            control={control}
            register={register}
            errors={errors}
            validationRules={{
              required: t("create_device_form.version_required"),
              validate: {
                isVersion: (value) =>
                  !isNaN(parseFloat(value)) ||
                  value.includes(".") ||
                  t("create_device_form.invalid_version"),
              },
            }}
            isReadOnly={readOnly}
          />
          <CreateDeviceTag
            control={control}
            errors={errors}
            isReadOnly={readOnly}
            deviceTags={tags}
          />
          <FormButtons
            onClose={onClose}
            isUpdateForm={!!deviceData}
            setReadOnly={setReadOnly}
            isReadOnly={readOnly}
            deleteId={deviceData?.id}
          />
        </form>
      </Box>
    </Box>
  );
}

export default CreateDevice;
