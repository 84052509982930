export const isOperatorAvailable = {
  UNDEFINED: 0,
  ONSHIFT: 1,
  AVAILABLE: 2,
  RESTING: 3,
  UNAVAILABLE: 4,
};

const resolveLastAccess = (lastAccess, t) => {
  if (lastAccess) {
    const date = new Date(lastAccess);
    const now = new Date();
    const diffMs = now - date; // Differenza in millisecondi

    const diffMinutes = Math.floor(diffMs / 60000); // Converti ms in minuti
    const diffHours = Math.floor(diffMinutes / 60); // Converti minuti in ore
    const diffDays = Math.floor(diffHours / 24); // Converti ore in giorni

    if (diffMinutes < 1) {
      return {
        status: isOperatorAvailable.ONSHIFT,
        lastAccess: t("online"),
      };
    } else if (diffMinutes < 20) {
      return {
        status: isOperatorAvailable.UNAVAILABLE,
        lastAccess: t("few_minutes_ago"),
      };
    } else if (diffMinutes < 60) {
      return {
        status: isOperatorAvailable.UNAVAILABLE,
        lastAccess: `${diffMinutes} ${t("minutes_ago")}`,
      };
    } else if (diffHours < 24) {
      return {
        status: isOperatorAvailable.UNAVAILABLE,
        lastAccess: `${diffHours} ${
          diffHours === 1 ? t("hour_ago") : t("hours_ago")
        }`,
      };
    } else {
      return {
        status: isOperatorAvailable.UNAVAILABLE,
        lastAccess: `${diffDays} ${
          diffDays === 1 ? t("day_ago") : t("days_ago")
        }`,
      };
    }
  } else {
    return {
      status: isOperatorAvailable.UNAVAILABLE,
      lastAccess: "-"
    };
  }
};

const mapUserAttributes = (users, t) => {
  return users?.map((user) => {
    var lastAccess = resolveLastAccess(user.lastAccess, t);
    return {
      enabled: user.enabled,
      id: user.id,
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      credentials: user.credentials,
      lastAccess: lastAccess.lastAccess,
      location: user.attributes?.location ? user.attributes.location[0] : null,
      number: user.attributes?.number ? user.attributes.number[0] : null,
      availability: lastAccess.status,
      email: user.email,
      groups: user.groups,
    };
  });
};

export default mapUserAttributes;
