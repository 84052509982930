import { config } from "config";
import groups from "./groups.json";
import { get, post, put, del } from "./APILib";

const host = config.endpoints.api;
const v = config.endpoints.api_version;

const dataStore = {
  groups: [...groups],
};

const API = {
  // GET methods
  getAllDevices: async () => get(`${host}/${v}/Device`),
  getDeviceById: async (id) => get(`${host}/${v}/Device/${id}`),
  getDeviceHistoryByDeviceId: async (id) =>
    get(`${host}/${v}/Device/history/${id}`),
  getAllEvents: async () => get(`${host}/${v}/Event`),
  getEventById: async (id) => get(`${host}/${v}/Event/${id}`),
  getAllOperators: async () => get(`${host}/${v}/Keycloak/users`),
  getOperatorHistoryByUsername: async (username) =>
    get(`${host}/${v}/Keycloak/user/${username}/history`),
  getAllGroups: async () => get(`${host}/${v}/Keycloak/groups`),
  getGroupOperators: async (group) =>
    get(`${host}/${v}/Keycloak/groups/${group.name}/users`),
  getUserGroups: async () => get(`${host}/${v}/Keycloak/user/groups`),
  getGroupPermissions: async () => {
    return;
  },
  getUserPermission: async () => {
    return;
  },
  getAllMeasures: async () => get(`${host}/${v}/Measure`),
  getAllMeasuresComplete: async () => get(`${host}/${v}/Measure/complete`),
  getMeasuresCount: async () => get(`${host}/${v}/Measure/count`),
  getMeasuresPaginated: async (
    offset,
    limit,
    globalFilter = "",
    sortBy = []
  ) => {
    const sortField = sortBy.length > 0 ? sortBy[0].id : "timestamp";
    const reversed = sortBy.length > 0 ? sortBy[0].desc : true;

    const params = {
      from: offset,
      number: limit,
      sortBy: sortField,
      reversed: reversed,
    };

    if (globalFilter) {
      params.filter = globalFilter;
    }

    return get(`${host}/${v}/Measure/complete/paginated`, params);
  },
  getAllVariable: async () => get(`${host}/${v}/Variable`),
  getAllTags: async () => get(`${host}/${v}/Tag`),
  getAllPlants: async () => get(`${host}/${v}/Plant`),
  getEventHistoryById: async (id) =>
    get(`${host}/${v}/EventHistory/event/${id}`),
  getAllDeviceTags: async (id) => get(`${host}/${v}/Tag/all-device-tags/${id}`),

  // POST methods
  createDevice: async (device) => post(`${host}/${v}/Device`, device),
  sendKeepalive: async () => post(`${host}/${v}/KeepAlive`),
  sendOperatorNotification: async (notification) => {
    return { isSuccess: true, data: "Notifica inviata!" };
  },
  createOperator: async (user) => post(`${host}/${v}/Keycloak/users`, user),
  createGroup: async (group) => post(`${host}/${v}/Keycloak/groups`, group),
  sendNotification: async (notification) =>
    post(`${host}/${v}/Notification/sendTopic`, notification),
  updateEventHistory: async (history) =>
    post(`${host}/${v}/EventHistory/`, history),
  setDeviceGroup: async (deviceId, groupId, permission) =>
    post(`${host}/${v}/DeviceGroup`, {
      deviceId: deviceId,
      groupId: groupId,
      permissionType: permission,
    }),

  // PUT methods
  editDevice: async (device) => put(`${host}/${v}/Device`, device),
  updateOperator: async (user) =>
    put(`${host}/${v}/Keycloak/users/${user.username}`, user),
  updateGroup: async (group) => {
    dataStore.groups = dataStore.groups.map((gr) =>
      group.name === gr.name ? group : gr
    );
    return { isSuccess: true, data: dataStore.groups };
  },

  // DELETE methods
  removeDevice: async (deviceId) => del(`${host}/${v}/Device/${deviceId}`),
};

export default API;
