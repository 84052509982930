import React from "react";
import { Box, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import API from "api/API";
import { useDevicesStore } from "contexts/stores";

const FormButtons = ({
  onClose,
  isUpdateForm,
  setReadOnly,
  isReadOnly,
  disableSave,
  deleteId,
}) => {
  const { t } = useTranslation();
  const { fetchDevices } = useDevicesStore();

  const deleteDevice = async (id) => {
    let isSure = window.confirm(t("confirm_delete_device"));
    if (isSure) {
      await API.removeDevice(id);
      fetchDevices();
    }
  };

  return (
    <Box
      p="0"
      display="flex"
      justifyContent="space-evenly"
      alignItems="center"
      position="sticky"
      top="0"
      // zIndex="1"
    >
      {isUpdateForm && isReadOnly && (
        <Button
          colorScheme="yellow"
          mt={4}
          onClick={() => setReadOnly(!isReadOnly)}
        >
          {t("edit")}
        </Button>
      )}
      {(!isReadOnly || !isUpdateForm) && (
        <Button
          type="submit"
          colorScheme="yellow"
          mt={4}
          isDisabled={!isReadOnly && isUpdateForm && disableSave}
        >
          {!isReadOnly && !isUpdateForm ? t("add") : t("save")}
        </Button>
      )}
      <Button colorScheme="yellow" mt={4} onClick={onClose}>
        {t("cancel")}
      </Button>
      {deleteId && (
        <Button
          mt={4}
          bgColor="red"
          _hover={{ bgColor: "darkRed" }}
          ml={"5px"}
          textColor="black"
          onClick={() => deleteDevice(deleteId)}
        >
          {t("delete")}
        </Button>
      )}
    </Box>
  );
};

export default FormButtons;
