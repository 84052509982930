import { useVariableStore } from "contexts/stores";

// Chakra imports
import {
  Box,
  Flex,
  Text,
  useBreakpointValue,
  Button,
  Collapse,
} from "@chakra-ui/react";
// Assets

// Custom components

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "components/generic/LoadingSpinner";
import FiltersTable from "components/filtersTable/FiltersTable";
import { useToggle } from "components/externalComponents/ToggleContext";

export default function SettingsPage() {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { variable, isUpdating, fetchVariable } = useVariableStore();
  const { t } = useTranslation();
  const bgColor = "pcr.200";
  const hoverColor = "pcr.100";

  const columnsList = [
    {
      Header: "variable_list.disabled",
      accessor: "toggle",
    },
  ];

  const [showTable, setShowTable] = useState(false); // Stato per gestire la visibilità della tabella
  const handleToggle = () => setShowTable(!showTable); // Funzione per alternare visibilità

  useEffect(() => {
    fetchVariable();
  }, [fetchVariable]);

  const { toggleStates, handleToggleChange } = useToggle(); // Accedi ai toggle

  return (!variable || variable.length === 0) && isUpdating ? (
    <LoadingSpinner />
  ) : (
    <Box bgColor="white" padding="15px" shadow={"lg"}>
      <Button
        onClick={handleToggle}
        bgColor={bgColor}
        _hover={{ bgColor: hoverColor }}
        ml={"5px"}
      >
        {showTable
          ? t("variable_list.hide_variableTable")
          : t("variable_list.show_variableTable")}{" "}
      </Button>

      <Collapse in={showTable} animateOpacity>
        <Box
          border="2px solid"
          borderColor="gray.300"
          borderRadius="md"
          p="1rem"
          mt="1rem"
        >
          {variable !== undefined && variable !== null ? (
            <FiltersTable
              columnsList={columnsList}
              tableData={variable}
              pageType={"variable_list"}
              toggleStates={toggleStates}
              onToggleChange={handleToggleChange} // Passiamo la funzione per gestire i toggle
            />
          ) : (
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              w={"100%"}
              minH={"100vh"}
            >
              <Flex alignItems={"center"}>
                <Text pl="0.5rem" fontSize="4xl" as={"b"}>
                  {t("empty_data")}
                </Text>
              </Flex>
            </Flex>
          )}
        </Box>
      </Collapse>

    </Box>
  );
}
