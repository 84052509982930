import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

const SliderPopup = ({ isOpen, onClose, children }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isKeyboardOpen = useDetectKeyboardOpen();

  return (
    <Drawer
      isOpen={isOpen}
      placement={isMobile ? "top" : "bottom"}
      onClose={onClose}
      autoFocus={!isMobile}
      allowPinchZoom={true}
      isFullHeight={isMobile && isKeyboardOpen ? false : true}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody
          overflow={isMobile ? "auto" : "visible"}
          overflowY="auto"
          maxH={isMobile && isKeyboardOpen ? "50vh" : "auto"}
        >
          {children}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default SliderPopup;
