import React from "react";
import { useMeasuresStore } from "contexts/stores";
import { Box } from "@chakra-ui/react";
import FiltersTable from "components/filtersTable/FiltersTable";

const MeasuresTable = () => {
  const { fetchMeasurePage } = useMeasuresStore(); // Fetch function

  const columnsList = [];
  const columnsVariables = [];

  // Define the fetchPage function
  const fetchPageData = async (pageIndex, itemsPerPage, globalFilter, sortBy) => {
    try {
      const result = await fetchMeasurePage(pageIndex, itemsPerPage, globalFilter, sortBy);
      return {
        data: result.data,         
        totalItems: result.totalItems,
      };
    } catch (error) {
      console.error("Error fetching page data:", error);
      return { data: [], totalItems: 0 };
    }
  };

  // Define pagination object
  const pagination = {
    fetchPage: fetchPageData,
  };

  return (
    <Box bgColor="white" padding="15px" shadow="lg">
      <FiltersTable
        columnsList={columnsList}
        columnsVariables={columnsVariables}
        pagination={pagination}
      />
    </Box>
  );
};

export default MeasuresTable;
