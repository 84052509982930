import {
  Tbody,
  Tr,
  Td,
  Flex,
  Text,
  Icon,
  Box,
  Button,
  Tooltip,
  Switch,
} from "@chakra-ui/react";
import { format, isMatch } from "date-fns";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import SliderPopup from "components/slider/SliderPopup";
import { useNavigate } from "react-router-dom";

//per DeviceTableContent e OperatorsTableContent
const maxTagsLength = 20;
const colorMap = {
  device_list: ["#7ce6b1", "pcr.100", "#f47b71"],
  events_list: ["#E0F7FA", "#64a8f3", "pcr.100", "#f47b71", "#7ce6b1"],
  operators_list: ["white", "#7ce6b1", "pcr.100", "#f47b71", "white"],
};

//per EventsTableContent
const bgColor = "pcr.200";
const hoverColor = "pcr.100";

const isValidDate = (dateString) => {
  const str = String(dateString);
  const correctedDateString = str.replace(/\.\d{3,}/, (match) =>
    match.substring(0, 4)
  );
  const datePattern = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z?)?$/;

  if (!datePattern.test(correctedDateString)) {
    return false;
  }

  const timestamp = new Date(dateString);
  return !isNaN(timestamp.getTime());
};

const isNumber = (str) => !isNaN(str) && !isNaN(parseFloat(str));
const isBoolean = (str) => str === "boolean";

function getDataTypes(value) {
  let type;

  if (value === null) {
    type = "null";
  } else if (isBoolean(typeof value)) {
    type = "enabled";
  } else if (Array.isArray(value)) {
    type = "textList";
  } else if (isNumber(value)) {
    type = "text";
  } else if (isValidDate(value)) {
    type = "date";
  } else {
    type = value || "text";
  }

  return type;
}

export const formatDate = (dateString) => {
  if (isMatch(dateString, "dd/MM/yyyy HH:mm")) {
    return dateString;
  }
  const date =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString
      : new Date(dateString);
  return format(date, "dd/MM/yyyy HH:mm");
};

const TableContent = ({
  page,
  prepareRow,
  textColor,
  columnsList,
  pageType,
  toggleStates,
  onToggleChange,
  rowClick,
}) => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState(null);
  const [openPopupState, setOpenPopupState] = useState({
    isOpen: false,
    component: null,
  });

  const navigate = useNavigate();

  const handleOpen = (row, component) => {
    setSelectedRow(row);
    setOpenPopupState({
      isOpen: true,
      component,
    });
  };

  const handleClose = () => {
    setOpenPopupState({
      isOpen: false,
      component: null,
    });
    setSelectedRow(null);
  };

  const renderers = {
    date: (cell, textColor) => (
      <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
        {formatDate(cell) || "-"}
      </Text>
    ),
    text: (cell, textColor) => (
      <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
        {cell || "-"}
      </Text>
    ),
    textIcon: (cell, textColor) => (
      <Box display={"flex"}>
        <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
          {cell || "-"}
        </Text>
        <Icon fontSize={"large"}>{cell?.column?.icon}</Icon>
      </Box>
    ),
    textList: (cellValue, textColor) => {
      let arrayValue = [];

      if (Array.isArray(cellValue)) {
        arrayValue = cellValue.map((item) => {
          return typeof item === "object"
            ? item?.name || JSON.stringify(item)
            : item;
        });
      } else if (typeof cellValue === "string") {
        arrayValue = cellValue.split(",").map((tag) => tag.trim());
      } else {
        arrayValue = [cellValue];
      }

      let result =
        arrayValue?.length === 1 ? arrayValue[0] : arrayValue.join(", ");

      if (result?.length > maxTagsLength) {
        let truncatedString = result.substring(0, maxTagsLength);
        const lastCommaIndex = truncatedString.lastIndexOf(",");
        result =
          lastCommaIndex > 0
            ? truncatedString.substring(0, lastCommaIndex) + " ..."
            : truncatedString + " ...";
      }

      return (
        <Tooltip label={result}>
          <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
            {result || "-"}
          </Text>
        </Tooltip>
      );
    },
    status: (value, textColor) => (
      <>
        <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
          {value !== null && value !== undefined
            ? t(`${pageType}.status_list.${value}`)
            : "-"}
        </Text>
      </>
    ),
    enabled: (value, textColor) => (
      <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
        {value ? t(`operators_list.enabled`) : "-"}
      </Text>
    ),
  };

  const defaultRenderer = (value, textColor) => (
    <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
      {value || "-"}
    </Text>
  );

  const formatValue = (cell) => {
    let type = getDataTypes(cell?.column?.type);
    const renderer = renderers[type] || defaultRenderer;
    return renderer(cell.value, textColor);
  };

  const renderStatusColor = (row) => {
    const statusValue = row.cells.find(
      (cell) => getDataTypes(cell?.column?.type) === "status"
    )?.value;

    if (statusValue !== undefined && statusValue !== null) {
      return colorMap[pageType][statusValue];
    }
    return "white";
  };

  const formatColumnsValue = (columnList, row) => {
    switch (columnList.accessor) {
      case "button":
        return columnList.customButton ? (
          columnList.customButton(row)
        ) : (
          <Button
            key={row.index}
            bgColor={bgColor}
            _hover={{ bgColor: hoverColor }}
            ml={"5px"}
            textColor="black"
            onClick={() => handleOpen(row, columnList.sliderPopupComponent)}
          >
            {t(columnList.Header)}
          </Button>
        );
      case "redirectButton":
        return (
          <Button
            key={row.index}
            bgColor={bgColor}
            _hover={{ bgColor: hoverColor }}
            ml={"5px"}
            textColor="black"
            onClick={() =>
              navigate(columnList.path, { state: { id: row.original.id } })
            }
          >
            {t(columnList.Header)}
          </Button>
        );
      case "toggle":
        return (
          <Flex direction="column">
            <Flex pt="5px">
              <Switch
                isChecked={toggleStates[row.original.kName] || false}
                onChange={(e) =>
                  onToggleChange(row.original.kName, e.target.checked)
                }
              />
            </Flex>
          </Flex>
        );
      default:
        return <span>{columnList.Header}</span>;
    }
  };

  return (
    <>
      <Tbody>
        {page.map((row) => {
          prepareRow(row);
          return (
            <Tr
              {...row.getRowProps()}
              key={row.id}
              bgColor={renderStatusColor(row)}
              onClick={() => {
                if (rowClick) {
                  handleOpen(row, rowClick.render);
                }
              }}
              cursor={"pointer"}
            >
              {row.cells.map((cell) => {
                let data = <Flex align="center">{formatValue(cell)}</Flex>;
                return (
                  <Td
                    {...cell.getCellProps()}
                    key={cell.id}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    borderColor="transparent"
                  >
                    {data}
                  </Td>
                );
              })}

              {columnsList.map((columList) => {
                if (columList.page === pageType) {
                  return (
                    <Td key={columList.Header}>
                      {formatColumnsValue(columList, row)}
                    </Td>
                  );
                }
                return null;
              })}
            </Tr>
          );
        })}
      </Tbody>
      {openPopupState.isOpen && openPopupState.component && (
        <SliderPopup isOpen={openPopupState.isOpen} onClose={handleClose}>
          {openPopupState.component(handleClose, selectedRow)}{" "}
        </SliderPopup>
      )}
    </>
  );
};

export default TableContent;
