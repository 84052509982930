import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Box, Text } from "@chakra-ui/react";
import "./Popup.css";
import MarkerClusterGroup from "react-leaflet-cluster";
import { DevIsAlive } from "views/devices/components/DeviceCardGrid";

const colorMap = ["#01B574", "yellow", "#EE5D50"];
const position = [41.8719, 12.5674]; // Posizione centrale (Italia)

const MapComponent = ({ devices, setEventFilter, dataSectionRef }) => {
  const [mapCenter, setMapCenter] = useState(null);

  useEffect(() => {
    if (devices.length > 0) {
      const bounds = L.latLngBounds(
        devices.map((device) => [device.latitude, device.longitude])
      );
      setMapCenter([bounds.getCenter().lat, bounds.getCenter().lng]);
    }
  }, [devices]);

  const getWorstState = (markers) => {
    let worstState = 0;
    markers.forEach((marker) => {
      const deviceState = marker.options.icon.options.html.includes(colorMap[2])
        ? DevIsAlive.ERROR
        : marker.options.icon.options.html.includes(colorMap[1])
        ? DevIsAlive.WARNING
        : DevIsAlive.OK;
      worstState = Math.max(worstState, deviceState);
    });
    return worstState;
  };

  const getClusterShape = (devices) => {
    const deviceTypes = new Set(devices.map((d) => d.type));
    if (deviceTypes.size === 1) {
      return deviceTypes.has("HMR9") ? "circle" : "square";
    } else {
      return "mix";
    }
  };

  return (
    <div style={{ height: "90vh", width: "100%", position: "relative" }}>
      {mapCenter && (
        <MapContainer
          center={mapCenter}
          zoom={8}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <MarkerClusterGroup
            maxClusterRadius={40}
            showCoverageOnHover={false}
            spiderfyOnEveryZoom={true}
            iconCreateFunction={(cluster) => {
              const markers = cluster.getAllChildMarkers();
              const worstState = getWorstState(markers);
              const devicesInCluster = markers.map(
                (marker) => marker.options.device
              );
              const clusterShape = getClusterShape(devicesInCluster);

              const borderRadius =
                clusterShape === "circle"
                  ? "50%"
                  : clusterShape === "square"
                  ? "0%"
                  : "0 50% 50% 0%";

              return L.divIcon({
                html: `<div style="background-color: ${
                  colorMap[worstState]
                }; width: 40px; height: 40px; border-radius: ${borderRadius}; display: flex; align-items: center; justify-content: center; color: black; font-size: 16px;">
                      ${cluster.getChildCount()}
                    </div>`,
                className: "custom-cluster-icon",
              });
            }}
            zoomToBoundsOnClick={false}
          >
            {devices.map((device) => (
              <Marker
                key={device.id}
                position={[device.latitude, device.longitude]}
                icon={L.divIcon({
                  className: "custom-icon",
                  html: `<div style="background-color: ${
                    colorMap[device.alive]
                  }; width: 30px; height: 30px; border-radius: ${
                    device.type === "HMR9" ? "50%" : "0%"
                  }; border: 1px solid white;"></div>`,
                })}
                device={device}
              >
                <Popup className="request-popup">
                  <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    bg={colorMap[device.alive]}
                    textAlign="center"
                    boxShadow="md"
                    border="1px"
                    width={"120px"}
                    height={"100px"}
                    cursor="pointer"
                    onClick={() => {
                      setEventFilter({
                        column: "deviceId",
                        value: device.name,
                      });
                      if (dataSectionRef?.current) {
                        dataSectionRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }
                    }}
                  >
                    <br />
                    <Text as="span" fontSize="md" fontWeight="bold">
                      {device.name}
                    </Text>
                    <br />
                    <br />
                    <Text as="span" fontWeight="bold">
                      {device.location}
                    </Text>
                    <br />
                    <Text as="span" fontWeight="bold">
                      {device.type}
                    </Text>
                  </Box>
                </Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
        </MapContainer>
      )}
    </div>
  );
};

export default MapComponent;
