// DeviceCardGrid.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Icon,
  Tooltip,
  Flex,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SliderPopup from "components/slider/SliderPopup";
import UpdateDeviceLayout from "./DeviceHistory/UpdateDeviceLayout";
import { useTranslation } from "react-i18next";

export const DevIsAlive = {
  OK: 0,
  WARNING: 1,
  ERROR: 2,
};

const cardColors = {
  [DevIsAlive.OK]: "green.500",
  [DevIsAlive.WARNING]: "yellow",
  [DevIsAlive.ERROR]: "red.500",
};

const cardTextColors = {
  [DevIsAlive.OK]: "black",
  [DevIsAlive.WARNING]: "black",
  [DevIsAlive.ERROR]: "black",
};

const search = (text, data) => {
  if (!text) return data;

  return data.filter((device) => {
    return (
      device.name.toLowerCase().includes(text.toLowerCase()) ||
      device.location.toLowerCase().includes(text.toLowerCase()) ||
      device.version.toLowerCase().includes(text.toLowerCase()) ||
      device.registrationDate.toLowerCase().includes(text.toLowerCase()) ||
      device.tags.some((tag) =>
        tag.name.toLowerCase().includes(text.toLowerCase())
      )
    );
  });
};

function DeviceCardGrid({ data, searchText, deleteDevice = (deviceId) => {} }) {
  const [filteredData, setFilteredData] = useState(data);

  const [isModalOpen, setIsModalOpen] = useState(
    new Array(data.length).fill(false)
  );
  const [selectedDeviceIndex, setSelectedDeviceIndex] = useState(null);
  const { t } = useTranslation();

  const handleOperatorSelect = (index) => {
    setSelectedDeviceIndex(index);
    setIsModalOpen((prev) =>
      prev.map((isOpen, i) => (i === index ? true : isOpen))
    );
  };

  const handleCloseModal = () => {
    setIsModalOpen((prev) =>
      prev.map((isOpen, i) => (i === selectedDeviceIndex ? false : isOpen))
    );
    setSelectedDeviceIndex(null);
  };

  useEffect(() => {
    setFilteredData(search(searchText, data));
  }, [searchText, data]);

  useEffect(() => {
    setIsModalOpen(new Array(filteredData.length).fill(false));
  }, [filteredData.length]);

  return (
    <Grid
      templateColumns="repeat(auto-fill, minmax(120px, 1fr))"
      gap={4}
      m={"20px"}
    >
      {filteredData.map((device, index) => (
        <GridItem key={index}>
          <Box
            key={index}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            bg={cardColors[device.alive]}
            p={"5px"}
            textAlign="center"
            boxShadow="md"
            border="1px"
            width={"120px"}
            height={"120px"}
            onClick={() => {
              handleOperatorSelect(index);
            }}
            cursor="pointer"
          >
            <Text
              fontSize="lg"
              fontWeight="bold"
              color={cardTextColors[device.alive]}
            >
              {device.name}
            </Text>
            <Text color={cardTextColors[device.alive]}>{device.location}</Text>
            <Text color={cardTextColors[device.alive]} pt={"10px"}>
              {device.type}
            </Text>
            <Flex pt={"10px"} justifyContent={"space-between"}>
              <Tooltip
                label={
                  Array.isArray(device.tags)
                    ? device.tags
                        .map((tag) =>
                          typeof tag === "object" ? tag.name : tag
                        )
                        .join(", ")
                    : device.tags.toString()
                }
              >
                <Icon color={cardTextColors[device.alive]}>
                  <SellOutlinedIcon />
                </Icon>
              </Tooltip>
              <Tooltip label={t("delete")}>
                <Icon
                  color={cardTextColors[device.alive]}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteDevice(device.id);
                  }}
                >
                  <DeleteOutlinedIcon />
                </Icon>
              </Tooltip>
            </Flex>
            <SliderPopup
              key={index}
              isOpen={isModalOpen[index]}
              onClose={handleCloseModal}
            >
              <UpdateDeviceLayout
                onClose={handleCloseModal}
                deviceData={device}
                deviceId={device.id}
              />
            </SliderPopup>
          </Box>
        </GridItem>
      ))}
    </Grid>
  );
}

export default DeviceCardGrid;
