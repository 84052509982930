import React, { useEffect, useMemo, useState } from "react";
import { Flex, Input, Box, Button, IconButton } from "@chakra-ui/react";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";
import { CloseIcon, AddIcon } from "@chakra-ui/icons";

const ColumnFilter = ({
  data,
  columns,
  pageType,
  applyFilter,
  setIsFilterActive,
  statusOptions,
}) => {
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [extraFilters, setExtraFilters] = useState([]);

  const { t } = useTranslation();

  const columnOptions = useMemo(
    () =>
      columns.map((column) => ({
        value: column.id,
        label: t(`${pageType}.${column.Header}`),
      })),
    [columns, t, pageType]
  );

  useEffect(() => {
    if (
      applyFilter !== "" &&
      applyFilter !== null &&
      applyFilter !== undefined
    ) {
      const column = columns.find((col) => col.id === applyFilter?.column);
      const value = applyFilter?.value;

      if (column) {
        columns.forEach((col) => col.setFilter(""));
        applyFilters({ column, value }, extraFilters, data);
        setInputValue(value);
        setSelectedColumn(column);
      }
    }
  }, [applyFilter]);
  const handleFilterChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    applyFilters({ column: selectedColumn, value }, extraFilters, data);
  };

  const handleColumnChange = (selectedOption) => {
    const column = columns.find((col) => col.id === selectedOption.value);
    setSelectedColumn(column);
    setInputValue("");
    applyFilters({ column, value: "" }, extraFilters, data);
  };

  const handleFilterChangeExtra = (e, index) => {
    const newFilters = [...extraFilters];
    newFilters[index].value = e.target.value;
    setExtraFilters(newFilters);
    applyFilters({ column: selectedColumn, value: inputValue }, newFilters);
  };

  const handleColumnChangeExtra = (selectedOption, index) => {
    const column = columns.find((col) => col.id === selectedOption.value);
    const newFilters = [...extraFilters];
    newFilters[index] = { column, value: "" }; // Aggiungi la colonna e resetta il valore
    setExtraFilters(newFilters);
    applyFilters({ column: selectedColumn, value: inputValue }, newFilters);
  };

  const applyFilters = (fixedFilter, extraFilters, data) => {
    // Reset dei filtri (se necessario)
    // columns.forEach((col) => col.setFilter(""));

    // Applica il filtro fisso
    if (fixedFilter.column && fixedFilter.column.setFilter) {
      if (fixedFilter.column.type === "boolean") {
        fixedFilter.column.setFilter(fixedFilter.value === "true");
      } else {
        // Gestione per altri tipi di dati

        fixedFilter.column.setFilter(
          fixedFilter.value === 0 ? "0" : fixedFilter.value
        );
      }
    }

    // Applicazione dei filtri aggiuntivi
    extraFilters.forEach((filter, index) => {
      if (filter.column && filter.column.setFilter) {
        if (filter.column.type === "boolean") {
          filter.column.setFilter(filter.value === "true");
        } else {
          filter.column.setFilter(filter.value === 0 ? "0" : filter.value);
        }
      }
    });

    setIsFilterActive(true);
  };

  const handleAddFilter = () => {
    setExtraFilters([...extraFilters, { column: null, value: "" }]);
  };

  const handleRemoveFilter = (index) => {
    const newFilters = [...extraFilters];
    if (newFilters[index].column && newFilters[index].column.setFilter) {
      newFilters[index].column.setFilter(""); // Reset del filtro
    }
    newFilters.splice(index, 1); // Rimuovi il filtro dall'array
    setExtraFilters(newFilters);
    applyFilters({ column: selectedColumn, value: inputValue }, newFilters);
  };

  const handleReset = () => {
    // Reset del filtro fisso
    setIsFilterActive(false);
    setSelectedColumn(null);
    setInputValue("");

    // Reset dei valori dei filtri aggiuntivi ma mantieni i filtri
    const resetExtraFilters = extraFilters.map((filter) => ({
      ...filter,
      column: null,
      value: "",
    }));
    setExtraFilters(resetExtraFilters);

    // Applica il reset a tutte le colonne
    columns.forEach((col) => col.setFilter(""));
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "#EEEEEE",
      borderColor: "black",
      boxShadow: "none",
      width: 180,
      "&:hover": {
        borderColor: "black",
      },
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999,
      position: "absolute",
    }),
  };

  const booleanOptions = [
    { value: "true", label: t("column_filter.value_enabled") },
    { value: "false", label: t("column_filter.value_no_enabled") },
  ];

  return (
    <Box>
      <Flex gap="2" direction="Column">
        <Flex direction="row" gap="2">
          {/* Filtro fisso */}
          <Flex direction="column" gap="2">
            <Box>{t("column_filter.column")}</Box>
            <ReactSelect
              menuPlacement="auto"
              options={columnOptions}
              onChange={handleColumnChange}
              value={
                selectedColumn
                  ? {
                      value: selectedColumn.id,
                      label: t(`${pageType}.${selectedColumn.Header}`),
                    }
                  : null
              }
              label={
                selectedColumn === null
                  ? t("column_filter.select_column")
                  : pageType === undefined
                  ? t(`${selectedColumn.Header}`)
                  : t(`${pageType}.${selectedColumn.Header}`)
              }
              placeholder={t("column_filter.select_column")}
              styles={customStyles}
            />
          </Flex>
          <Flex direction="column" gap="2">
            <Box>{t("column_filter.value")}</Box>
            {selectedColumn && typeof selectedColumn.type === "boolean" ? (
              <ReactSelect
                options={booleanOptions}
                onChange={(option) => {
                  setInputValue(option.value);
                  applyFilters(
                    { column: selectedColumn, value: option.value },
                    extraFilters,
                    data
                  );
                }}
                value={
                  inputValue
                    ? {
                        value: inputValue,
                        label:
                          inputValue === "true"
                            ? t("column_filter.value_enabled")
                            : t("column_filter.value_no_enabled"),
                      }
                    : null
                }
                placeholder={t("column_filter.filter")}
                styles={customStyles}
              />
            ) : selectedColumn && selectedColumn.type === "status" ? (
              <ReactSelect
                options={statusOptions}
                onChange={(option) => {
                  setInputValue(option.value);
                  applyFilters(
                    { column: selectedColumn, value: option.value },
                    extraFilters,
                    data
                  );
                }}
                value={
                  statusOptions.find((opt) => opt.value === inputValue) || null
                }
                placeholder={t("column_filter.select_status")}
                styles={customStyles}
              />
            ) : (
              <Input
                value={inputValue}
                focusBorderColor="black"
                backgroundColor={"#EEEEEE"}
                sx={{
                  "::placeholder": {
                    color: "black",
                  },
                }}
                placeholder={t("column_filter.filter")}
                onChange={handleFilterChange}
                width={"100%"}
                maxWidth={"200px"}
              />
            )}
          </Flex>
        </Flex>

        {/* Filtri aggiuntivi */}
        {extraFilters.map((filter, index) => (
          <Flex key={index} direction="row" gap="2" mt="10px">
            <Flex direction="column" gap="2">
              <ReactSelect
                menuPlacement="auto"
                options={columnOptions}
                onChange={(option) => handleColumnChangeExtra(option, index)}
                placeholder={t("column_filter.select_column")}
                styles={customStyles}
              />
            </Flex>
            <Flex direction="column" gap="2">
              {filter.column && typeof filter.column.type === "boolean" ? (
                <ReactSelect
                  options={booleanOptions}
                  onChange={(option) => {
                    const newFilters = [...extraFilters];
                    newFilters[index].value = option.value;
                    setExtraFilters(newFilters);
                    applyFilters(
                      { column: selectedColumn, value: inputValue },
                      newFilters
                    );
                  }}
                  value={
                    filter.value
                      ? {
                          value: filter.value,
                          label:
                            filter.value === "true"
                              ? t("column_filter.value_enabled")
                              : t("column_filter.value_no_enabled"),
                        }
                      : null
                  }
                  placeholder={t("column_filter.filter")}
                  styles={customStyles}
                />
              ) : filter.column && filter.column.type === "status" ? (
                <ReactSelect
                  options={statusOptions}
                  onChange={(option) => {
                    setInputValue(option.value);
                    applyFilters(
                      { column: selectedColumn, value: option.value },
                      extraFilters,
                      data
                    );
                  }}
                  value={
                    statusOptions.find((opt) => opt.value === inputValue) ||
                    null
                  }
                  placeholder={t("column_filter.select_status")}
                  styles={customStyles}
                />
              ) : (
                <Input
                  value={filter.value}
                  focusBorderColor="black"
                  backgroundColor={"#EEEEEE"}
                  sx={{
                    "::placeholder": {
                      color: "black",
                    },
                  }}
                  placeholder={t("column_filter.filter")}
                  onChange={(e) => handleFilterChangeExtra(e, index)}
                  width={"100%"}
                  maxWidth={"200px"}
                />
              )}
            </Flex>
            <IconButton
              aria-label="Remove filter"
              icon={<CloseIcon />}
              onClick={() => handleRemoveFilter(index)}
            />
          </Flex>
        ))}

        {/* Pulsante per aggiungere un nuovo filtro */}
        <IconButton
          bgColor={"pcr.200"}
          _hover={{ bgColor: "pcr.100" }}
          onClick={handleAddFilter}
          icon={<AddIcon />}
        />
      </Flex>

      {/* Pulsante per resettare i filtri */}
      <Button
        bgColor={"pcr.200"}
        _hover={{ bgColor: "pcr.100" }}
        mt={"20px"}
        onClick={handleReset}
      >
        {t("column_filter.reset")}
      </Button>
    </Box>
  );
};

export default ColumnFilter;
