import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Button, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

const customMarkerIcon = new L.Icon({
  iconUrl: markerIcon,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  shadowUrl: markerShadow,
  shadowSize: [41, 41],
});

const LocationPicker = ({ onLocationSelect, onClose, selectedCoordinates }) => {
  const { t } = useTranslation();

  const [position, setPosition] = useState(
    selectedCoordinates &&
      selectedCoordinates[0] !== "" &&
      selectedCoordinates[1] !== ""
      ? selectedCoordinates
      : [41.8719, 12.5674]
  );

  const [address, setAddress] = useState(null);

  const fetchAddress = async (lat, lng) => {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
    );
    if (response.ok) {
      const data = await response.json();
      setAddress(data);
    } else {
      console.error("Error fetching address:", response.status);
    }
  };

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        const newPosition = [
          parseFloat(e.latlng.lat.toFixed(5)),
          parseFloat(e.latlng.lng.toFixed(5)),
        ];
        setPosition(newPosition);
        fetchAddress(newPosition[0], newPosition[1]); // Fetch address on marker position
      },
    });

    return position ? (
      <Marker position={position} icon={customMarkerIcon}></Marker>
    ) : null;
  };

  return (
    <div style={{ position: "relative", height: "90vh", width: "100%" }}>
      <MapContainer
        center={position}
        zoom={6}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker />
      </MapContainer>

      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          zIndex: 1000,
        }}
      >
        <Text mb={2}>Coordinate: {position.join(", ")}</Text>

        {address && (
          <Text mb={2}>
            {address.address?.city ||
              address.address?.town ||
              address.address?.village ||
              address.address?.municipality ||
              address.address?.state}
          </Text>
        )}

        <Button
          bgColor={"pcr.200"}
          _hover={{ bgColor: "pcr.100" }}
          onClick={() => {
            onLocationSelect(
              position,
              address.address?.city ||
                address.address?.town ||
                address.address?.village ||
                address.address?.municipality ||
                address.address?.state
            );
            onClose();
          }}
          mr={1}
        >
          {t("confirm_coordinates")}
        </Button>
        <Button
          bgColor={"pcr.200"}
          _hover={{ bgColor: "pcr.100" }}
          onClick={() => {
            onClose();
          }}
        >
          {t("cancel")}
        </Button>
      </div>
    </div>
  );
};

export default LocationPicker;
