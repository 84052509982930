// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
// Custom Components
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// Assets
import { useKeycloak } from "contexts/stores";
import LogoutIcon from "@mui/icons-material/Logout";
import IT from "country-flag-icons/react/3x2/IT";
import EN from "country-flag-icons/react/3x2/GB";
import { useTranslation } from "react-i18next";
import { useLoggedAccountStores } from "contexts/stores";
import UserRole from "utils/UserRole";

export default function HeaderLinks(props) {
  const { secondary } = props;
  // Chakra Color Mode
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const keycloak = useKeycloak((state) => state.keycloak);
  const roles = useLoggedAccountStores((state) => state.roles);
  const [userInfo, setUserInfo] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    keycloak.loadUserInfo().then((userInfo) => {
      setUserInfo(userInfo);
    });
  }, [keycloak]);
  return (
    <Flex
      w="100%"
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: "pointer" }}
            color="black"
            name={userInfo?.preferred_username}
            bg="pcr.200"
            size="md"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="10px"
          bg={menuBg}
          border="1px"
        >
          <VStack w="90%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              w="100%"
              fontSize="lg"
              fontWeight="700"
              color={textColor}
            >
              {t("welcome")}, {userInfo?.preferred_username}
            </Text>
            <Text
              ps="20px"
              pb="10px"
              w="100%"
              fontWeight="700"
              fontSize="sm"
              color={textColor}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              {roles.includes(UserRole.ADMINISTRATOR) ? t("admin") : t("user")}
            </Text>
          </VStack>

          <HStack marginLeft="20px" marginRight="20px" marginTop="10px">
            <IconButton
              icon={<IT />}
              onClick={() => {
                i18n.changeLanguage("it");
              }}
              variant={i18n.language === "it" ? "solid" : "unstyled"}
              colorScheme={i18n.language === "it" ? "teal" : ""}
            />

            <IconButton
              icon={<EN />}
              onClick={() => {
                i18n.changeLanguage("en");
              }}
              variant={i18n.language === "en" ? "solid" : "unstyled"}
              colorScheme={i18n.language === "en" ? "teal" : ""}
            />
          </HStack>
          <Button
            size="md"
            onClick={() => {
              keycloak.login({
                action: "UPDATE_PASSWORD",
              });
            }}
            style={{
              width: "95%",
              margin: "5px",
              marginBottom: "10px",
              paddingLeft: "5px",
            }}
            color={textColor}
          >
            {t("password_recovery")}
          </Button>

          <Button
            size="md"
            onClick={() => {
              keycloak.logout();
            }}
            style={{
              width: "95%",
              margin: "5px",
              marginBottom: "10px",
              paddingLeft: "5px",
            }}
            color={textColor}
          >
            <LogoutIcon style={{ marginRight: "10px" }} />
            {t("logout")}
          </Button>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
