import React, { useState } from "react";
import {
  Button,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Portal,
} from "@chakra-ui/react";
import ColumnFilter from "components/filtersTable/DefaultColumnFilter";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTranslation } from "react-i18next";
const bgColor = "pcr.200";
const hoverColor = "pcr.100";
const bgColorActive = "black";
const textColorActive = "pcr.200";
const iconColorActive = "#ffd00d";

export default function FilterButton({
  allColumns,
  tableData,
  pageType,
  applyFilter,
  statusOptions,
}) {
  const { t } = useTranslation();
  const [isFilterActive, setIsFilterActive] = useState(false);

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          bgColor={isFilterActive ? bgColorActive : bgColor}
          _hover={{ bgColor: hoverColor }}
          ml={"5px"}
        >
          {" "}
          <FilterListIcon
            style={{ color: isFilterActive ? iconColorActive : bgColorActive }}
          />{" "}
          <Text
            pl={"2px"}
            color={isFilterActive ? textColorActive : bgColorActive}
          >
            {t("column_filter.button")}
          </Text>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent borderColor={"black"} width="400px">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>{t("column_filter.title")}</PopoverHeader>
          <PopoverBody>
            <ColumnFilter
              columns={allColumns.filter((col) => col.canFilter)}
              data={tableData}
              pageType={pageType}
              applyFilter={applyFilter}
              setIsFilterActive={setIsFilterActive}
              statusOptions={statusOptions}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
