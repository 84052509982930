import React, { useEffect, useState } from "react";
import { Box, Button, Heading } from "@chakra-ui/react";
import API from "api/API";
import { useTranslation } from "react-i18next";
import TimelineComponent from "./TimelineComponent";

import { useNavigate } from "react-router-dom";

export const Status = {
  NEW: 0,
  CONFIRMED: 1,
  TICKET: 2,
  CANCELED: 3,
  CLOSED: 4,
};

const OperatorHistory = ({ operatorUsername, onClose }) => {
  const [data, setData] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    API.getOperatorHistoryByUsername(operatorUsername || "").then((result) => {
      if (result.isSuccess) {
        setData(result.data);
      } else {
        console.error("failed fetch data");
      }
    });
  }, [operatorUsername]);

  return (
    <Box p={4} height="100%">
      <Box
        bg="white"
        p={4}
        height="100%"
        display="flex"
        flexDirection="column"
        position="relative"
      >
        <Heading size="md" pb="20px" textAlign="center">
          {t("operator_history")}
          <br />
          {data[0]?.name}
          <Button
            colorScheme="yellow"
            mt={4}
            position="absolute"
            right="4"
            top="4"
            onClick={() => {
              operatorUsername ? onClose() : navigate("/operators");
            }}
          >
            {t("close")}
          </Button>
        </Heading>

        <Box flex="1" overflowY="auto">
          {Array.isArray(data) && data?.length > 0 && (
            <TimelineComponent logs={data} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default OperatorHistory;
